import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, EntitySelectReplaceable, PageLayoutStandard } from '@shapeable/ui';
import { RestorationProjectGrid } from '../entities/restoration-project-grid';
import { EntitySelect } from '../entities/entity-select';
import { SiteMetricsBubbles } from '../entities/site-metrics-bubbles';
import { SliceLayoutRestorationProjectGrid } from '../slice-layouts';

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    .shp--page-layout-standard__title {
      margin-bottom: 0 !important;
    }
  `,
});

const CountsStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(8)};
    margin-bottom: ${theme.UNIT(3)};
  `,
  tablet: css`
    margin-bottom: ${theme.UNIT(6)};
  `,
});


const GridStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(2)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled(PageLayoutStandard)`${ContainerStyles}`,
    Counts: styled(SiteMetricsBubbles)`${CountsStyles}`,
    Grid: styled(RestorationProjectGrid)`${GridStyles}`,

};

export const PageLayoutRestorationProjects: PageLayoutComponent = (props) => {
  const { className } = props;
  const entity = useEntity(props.entity);

  const slices = <>
    {props.slices}
    <SliceLayoutRestorationProjectGrid />
  </>;

  return (
    <EntitySelectReplaceable.Provider value={EntitySelect}>
      <My.Container 
        headerProps={{ showSlideControls: true, aspectRatio: { base: 24/9 }, showOverlayGradient: true, variant: 'overlay' }}
        afterBannerTitles={<My.Counts />}
        slices={slices}
        className={className}
        entity={entity} 
      /> 
    </EntitySelectReplaceable.Provider>
  );
};

