import React from 'react';
import styled, { css } from 'styled-components';
import { Entity, Page, PageLayoutComponent, PageType, Slice } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, ImageEntity, SliceLayoutBoundary, usePages, useVideos, useFeedEntries, ComponentPropsProvider, EntityTitleHeader } from '@shapeable/ui';
import { BANNER_KNOWLEDGE_HUB, ROADMAP_THUMBNAIL } from '../../data';
import { DARK_BLUE_OVERLAY } from '../../theme';
import { SliceLayoutKnowledgeHubIntro } from '../slice-layouts';
import { CitationCard } from '../entities/citation-card';
import { filterPagesByType } from '../../utils/filter-pages-by-type';
import { sortBy } from 'lodash';
import { PageKnowledgeHubLayout } from '../entities/page-knowledge-hub-layout';
import { filterNewsArticles, filterNonNewsArticles } from '../../utils/entity-type-replacement';

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
};

export const PageLayoutKnowledgeHub: PageLayoutComponent = (props) => {
  const { className, slices } = props;
  const entity = useEntity(props.entity);

  const pages = usePages();
  const feedEntries = useFeedEntries();



  //Below util functions used to update entity type names for KnowledgeHub

  const resourceFeedEntries = filterNonNewsArticles(feedEntries);
  const resourceNews = filterNewsArticles(feedEntries);
  const resourcePages = filterPagesByType(pages, 'Project Case Study');

  const allResourceItems = sortBy([...useVideos(), ...resourceFeedEntries, ...resourceNews, ...resourcePages], 'name');

  // const instructionPage = filterPagesByType(pages, 'Instruction Manual').filter(page => page.name === 'Instruction Manual');
  const publications = [...filterPagesByType(pages, 'KFA Publication')];

  const newPublicationItems = publications.map((entity: Page) => 
    !!entity && entity?.name === 'Roadmap' ? {
      ...entity,
      openGraph: ROADMAP_THUMBNAIL,
    } : entity
  );

  const contentSlice: Slice = {
    id: 'intro',
    content: entity.content,
    images: entity.imageAssets,
    links: [
      { 
        id: 'link-guidebook',
        url: 'https://bit.ly/kelprestore',
      }
    ]
  };

  const types = {
    Publications: {
      label: 'KFA Publications',
      spacing: 2,
      items: newPublicationItems,
    },
    Entity: {
      label: 'Resources',
      latestTitle: 'Latest News',
      sortBy: 'published',
      reverse: true,
      spacing: 2,
      filterFieldNames: ['types', 'economies', 'species', 'genera', 'methodologies'],
      items: allResourceItems,
      toggleTypes: [
        {
          name: 'FeedEntry',
          label: 'Articles',
        },
        {
          name: 'News',
          label: 'News',
        },
        {
          name: 'Video',
          label: 'Videos',
        },
        {
          name: 'Page',
          label: 'Case Studies',
        },
        
      ],
    },
    Citation: {
      label: 'Scientific Papers',
      cardComponent: CitationCard,
      spacing: 4,
      filterFieldNames: ['authors', 'organisations', 'restorationProjects', 'economies', 'genera', 'species', 'methodologies'],
    },
    Post: {
      label: 'Posts',
      spacing: 2,
      latestTitle: 'Latest Posts',
      sortBy: 'published',
      reverse: true,
    },
  };

  return (
    <ComponentPropsProvider value={{ 
      FeedEntryCard: {
        usePath: true,
      }
    }}>
    <My.Container className={className}>
      <EntityTitleHeader overlayColor={DARK_BLUE_OVERLAY} backgroundImage={BANNER_KNOWLEDGE_HUB} entity={entity} />
      <SliceLayoutKnowledgeHubIntro entity={contentSlice} />
      {slices}
      <SliceLayoutBoundary>
      <PageKnowledgeHubLayout
        entity={entity}
        filterSize='slim'
        filterSpacing={3}
        showAllTab={true}
        allTabLabel='All Knowledge'
        entityTypes={types}
      />
      </SliceLayoutBoundary>
    </My.Container>
    </ComponentPropsProvider>
  );
};