import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { EventLayout } from '../entities/event-layout';
import { SECONDARY_BLUE } from '../../theme';
import { EntityTitleHeader, PageHeader, SiteHeaderProvider, usePastEvents, useUpcomingEvents } from '@shapeable/ui';
const cls = classNames('page-layout-events');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${HeaderStyles}`,
    Header: styled(PageHeader)`${ContainerStyles}`,
    Layout: styled(EventLayout)`${LayoutStyles}`,
};

export const PageLayoutEvents: PageLayoutComponent = 
(props) => {
  const { className, slices, entity } = props;

  return (
    <SiteHeaderProvider value={{
      variant: 'overlay',
    }}>
    <My.Container className={cls.name(className)}>
      <EntityTitleHeader entity={entity} backgroundColor={SECONDARY_BLUE + 'D9'} />
      {slices}
    </My.Container>
    </SiteHeaderProvider>
  )
};