import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Event, Color } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, formatDate, formatDateString } from '@shapeable/utils';
import { CellHeader, ComponentPropsProvider, ContentNode, EntityMetaSection, EntityMultiValueList, EntityValue, EventDateLocation, EventSessionTabs, IconTextValue, LinkButton, MainAside, PageHeader, SessionList, SliceIntro, SliceLayoutBoundary, useContentSlice, useLang, useReady } from '@shapeable/ui';
import { filter, find, uniq, flatten, uniqBy } from 'lodash';
import { COLOR_LIGHT_BROWN, OCEAN_TIEL, ORANGE } from '../../theme';
import { ExternalLinkIconGlyph, GlobeIconGlyph } from '@shapeable/icons';
import { SliceLayoutIntro } from '../slice-layouts';
const cls = classNames('event-layout');

// -------- Types -------->

// -------- Props -------->

export type EventLayoutProps = Classable & HasChildren & { 
  entity?: Event;
};

export const EventLayoutDefaultProps: Omit<EventLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const AsideLabelStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const PretitleStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
    font-weight: 300;
    color: ${theme.COLOR('light')};
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-size: 3em;
    line-height: 1em;
    font-weight: 500;
    color: ${theme.COLOR('light')};
    margin-top: 0;
    margin-bottom: ${theme.UNIT(14)};
  `,

});

const IntroSliceStyles = breakpoints({
  base: css`
    font-size: 0.8em;
    text-align: center;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    width: 100%;
    margin-bottom: ${theme.UNIT(6)};
  `,
});

const MainAsideStyles = breakpoints({
  base: css`
    padding: 0;
  `,
  tablet: css`
    padding: ${theme.UNIT(4)} 0;
  `,
});

const AsideStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(6)};
  `,
  desktop: css`
    padding-left: 0;
    padding-right: 0;
  `,
});

const AsideItemStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};

  `,
});

const DateAndLocationStyles = breakpoints({
  base: css`
    ${AsideItemStyles};
  `,
});

const KeyIssuesStyles = breakpoints({
  base: css`
    ${AsideItemStyles};
  `,
});

const OnlineEventStyles = breakpoints({
  base: css`
    ${AsideItemStyles};
  `,
});

const EventLinkStyles = breakpoints({
  base: css`
  padding: 0 0 ${theme.UNIT(2)} 1px;
  font-style: italic;
  ${AsideItemStyles};

  &:hover {
    ${theme.TRANSITION('color')};
  }
  `,
});

const MetaSectionStyles = breakpoints({
  base: css`
    ${AsideItemStyles}
    .shp--cell-label {
      color: ${theme.COLOR('strong')};
      font-weight: 400;
    };
  `,
});

const TabsStyles = breakpoints({
  base: css`
    position: relative;
    width: 100%;
    padding-top: ${theme.UNIT(2)};
    padding-bottom: ${theme.UNIT(6)};
    background: linear-gradient(to bottom, ${theme.COLOR('bright')}FF 0%, ${theme.COLOR('bright')}FF 75%, ${theme.COLOR('bright')}00 100%);
    overflow: hidden;
  `,
});

const AgendaStyles = breakpoints({
  base: css`
  `,
});

const SessionsStyles = breakpoints({
  base: css`
   
    padding-bottom: ${theme.UNIT(10)};
  `,
});

const SessionInfoStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(6)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(PageHeader)`${HeaderStyles}`,
      PreTitle: styled.h4`${PretitleStyles}`,
      Title: styled.h1`${TitleStyles}`,

    AsideLabel: styled(CellHeader)`${AsideLabelStyles}`,

    Intro: styled(SliceLayoutIntro)`${IntroSliceStyles}`,

    Body: styled.div`${BodyStyles}`,
      MainAside: styled(MainAside)`${MainAsideStyles}`,
        Tabs: styled(EventSessionTabs)`${TabsStyles}`,
        Agenda: styled.div`${AgendaStyles}`,
        Sessions: styled(SessionList)`${SessionsStyles}`,
        SessionInfo: styled(ContentNode)`${SessionInfoStyles}`,

      Aside: styled.div`${AsideStyles}`,
        DateAndLocation: styled(EventDateLocation)`${DateAndLocationStyles}`,
        OnlineEvent: styled(IconTextValue)`${OnlineEventStyles}`,
        KeyIssues: styled(EntityMultiValueList)`${KeyIssuesStyles}`,
        MetaSection: styled(EntityMetaSection)`${MetaSectionStyles}`,

        EventLink: styled(IconTextValue)`${EventLinkStyles}`,
};

export const EventLayout: Shapeable.FC<EventLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { title, name, overview, startDate, endDate, keyIssues = [], type, isVirtual, url, sessionInfo } = entity;

  const hasKeyIssues = !!keyIssues.length;
  const hasTitle = !!title;

  

  const eventTitle = title || name;

  const hasDateAndLocation = !!startDate || !!endDate || !!entity.location;

  const t = useLang();

  const sessions = filter(entity.sessions || [], session => !!session.startDate);

  const days = uniq(sessions.map(session => formatDateString(session.startDate, 'YYYY-MM-DD')));

  const today = formatDate(new Date(), 'YYYY-MM-DD');

  const isReady = useReady();

  const defaultActiveDay = (isReady && find(days, day => day === today)) || (days.length && days[0]);

  const [ activeDay, setActiveDay ] = React.useState<string>(defaultActiveDay);

  const activeSessions = filter(sessions, session => (formatDateString(session.startDate, 'YYYY-MM-DD') === activeDay));

  const sessionParticipants = uniqBy(flatten(sessions.map(session => session.presenters.map(presenter => presenter))), 'id');
  const hasParticipants = !!sessionParticipants.length;

  const hasSessions = !!sessions.length;
  const hasOverview = !!overview.text;

  const tabOnClick = (day: string) => {
    setActiveDay(day); 
  };

  const overviewSlice = useContentSlice({
    intro: overview,
    backgroundColor: COLOR_LIGHT_BROWN,
  });
  
  return (
    <ComponentPropsProvider value={{ 
      SessionLayout: {
        showDownloadLinks: false,
        showPresenters: false,
      }
     }}>
    <My.Container className={cls.name(className)}>
      <My.Header entity={entity} variant='overlay' >
        {
          hasTitle &&
          <>
            <My.PreTitle>{t(type?.name || 'Event')}</My.PreTitle>
            <My.Title>{eventTitle}</My.Title>
          </>
        }
      </My.Header>
      { 
        hasOverview && <My.Intro entity={overviewSlice}  /> 
      }
      <SliceLayoutBoundary verticalPadding='content' boundary='content' >
      <My.Body>
        <My.MainAside
          aside={
            <My.Aside>
              {
                hasDateAndLocation &&
                  <My.DateAndLocation entity={entity} />
              }
              {
                isVirtual && 
                  <My.OnlineEvent iconComponent={GlobeIconGlyph} iconSize={24} color='strong'>{t("Online Event")}</My.OnlineEvent>
              }
              { 
                url && 
                <My.EventLink href={url} iconComponent={ExternalLinkIconGlyph} iconSize={22} color='strong'>{t('View Event Details')}</My.EventLink>
              }
              {
                hasKeyIssues &&
                  <My.KeyIssues items={keyIssues} />
              }
              {
                hasParticipants && 
                  <My.MetaSection label="Participants:" items={sessionParticipants} />
              }
            </My.Aside>
          }>
          
            <My.Tabs
              entity={entity} 
              activeColor={ORANGE}
              activeDay={activeDay}
              lineColor={OCEAN_TIEL}
              lineWidth={2}
              lineVariant="solid"
              onDateChange={tabOnClick}
            />
          {
            hasSessions ? (
              <My.Sessions groupByTimeOfDay items={activeSessions} />
            ) : (
              <My.Agenda>
                <My.SessionInfo entity={sessionInfo} />
              </My.Agenda>
            )
          }
          

        </My.MainAside>
      </My.Body>

    </SliceLayoutBoundary>
    {children}
    </My.Container>
    </ComponentPropsProvider>
  )
};

EventLayout.defaultProps = EventLayoutDefaultProps;
EventLayout.cls = cls;