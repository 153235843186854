import React from 'react';
import styled, { css } from 'styled-components';
import { Menu, PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { SliceLayoutBoundary, useEntity, NextPageLink, useLinearNavigation, ContentEntityLayout, EntityTitleHeader } from '@shapeable/ui';
import { DARK_BLUE_OVERLAY } from '../../theme';
import { ORGANISATION_BANNER } from '../../data';
import { AsideLayout } from '../entities/aside-layout';
import { classNames } from '@shapeable/utils';

const cls = classNames('page-layout-content');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const ContentLayoutStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)};
    margin-top: ${theme.UNIT(8)};

    h2 {
      font-weight: 500;
    }
  `,
  tablet: css`
    margin-top: ${theme.UNIT(10)};
  `,
  desktop: css`
    margin-top: ${theme.UNIT(12)};
  `,
});

const NextLinkStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    ContentLayout: styled(ContentEntityLayout)`${ContentLayoutStyles}`,
    NextLink: styled(NextPageLink)`${NextLinkStyles}`,
};

export const PageLayoutContent: PageLayoutComponent = 
(props) => {
  const { className, children, slices } = props;
  const entity = useEntity(props.entity);

  const info = useLinearNavigation();

  const { isLinearNav } = info;
  
  return (
    <My.Container className={className}>
      <EntityTitleHeader overlayColor={DARK_BLUE_OVERLAY} backgroundImage={ORGANISATION_BANNER} entity={entity} showPretitle />
      <SliceLayoutBoundary>
        <My.ContentLayout 
        showTitles
        showMeta={true}
        showImage={false}
        asideLayout={isLinearNav ? <AsideLayout /> : null}
        isAsideSticky={true}
        entity={entity} />
        <My.NextLink />
      </SliceLayoutBoundary>
    </My.Container>
  )
};