import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints } from '@shapeable/theme';
import { 
  PageContextDefinition, FormProvider, ModalStateProvider, 
  AppStateProvider, useReady, 
  LinkButtonReplaceable, 
  WARM_GREY, 
  PersonValue,
  LayoutShell,
  SiteFooterLayout,
  UiProvider,
  UiConfig,
  DataHook
} from '@shapeable/ui';
import { DEFAULT_MAP_ENTITY_TYPE_NAMES } from '../data';
import * as pageLayouts from './page-layouts';
import * as sliceLayouts from './slice-layouts';
import * as blockLayouts from './block-layouts';
import * as icons from './icons';

import { RestorationProjectsProvider } from '../gatsby/providers/restoration-projects-provider';
import { LinkButton } from './elements/link-button';
import { OrganisationsProvider } from '../gatsby/providers/organisations-provider';
import { OrganisationPledgesProvider } from '../gatsby/providers/organisation-pledges-provider';
import { OrganisationTooltip } from './entities/organisation-tooltip';
import { OrganisationPledgeTooltip } from './entities/organisation-pledge-tooltip';
import { DARK_BLUE, LayoutStyles, OCEAN_BLUE, ORANGE, themeOverrides } from '../theme';
import { SiteSidebarProvider } from './context/site-sidebar-provider';
import { FeedEntriesProvider } from '../gatsby/providers/feed-entries-provider';
import { CitationsProvider } from '../gatsby/providers/citations-provider';
import { ArticleTypeIcon } from './icons/article-type-icon';
import { VideosProvider } from '../gatsby/providers/videos-provider';
import { PagesProvider } from '../gatsby/providers/pages-provider';
import { PostsProvider } from '../gatsby/providers/posts-provider';
import { LinearNavigationProvider } from './providers/linear-navigation-provider';
import { MapConfig, MapState, MapProvider } from '@shapeable/maps';
import { LocationIcon, OrganisationIconGlyph, OrganisationTypeIcon, PersonIcon, PledgeIcon, RestorationProjectIcon, useIcons } from '@shapeable/icons';
import { EntityMapPanel } from './entities/entity-map-panel';
import { RestorationProjectTooltip } from './entities/restoration-project-tooltip';
import { MapPledgeIcon } from './elements/map-pledge-icon';
import { RestorationProjectMarker } from './entities/restoration-project-marker';
import { useRestorationProjects } from '../hooks/use-restoration-projects';

const initialMapState: MapState = {
  isVisible: false,
  isInitialized: false,
  zoom: 2,
  entityTypeNames: DEFAULT_MAP_ENTITY_TYPE_NAMES,
  latitude: -5.441022303718128,
  longitude: 117.59765625000352,
};

export type LayoutPropTypes = Classable & HasChildren & {
  path?: string;
  data?: any;
  pageContext?: PageContextDefinition;
};

const LayoutDefaultProps: LayoutPropTypes = {
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${LayoutStyles};
  `,
  tablet: css`
  `,
});

const MapStyles = breakpoints({
  base: css`
    
  `,
});

const FooterStyles = breakpoints({
  base: css`
    
  `,
});




// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,
  Map: styled(EntityMapPanel)`${MapStyles}`,
  Footer: styled(SiteFooterLayout)`${FooterStyles}`,
};

export const Layout: React.FC<LayoutPropTypes> = (props) => {
  const { className, children } = props;
  const { isReady } = useReady();

  const mapConfig: MapConfig = {
    initialMapState,
    entityTypeMapMarkers: {
      RestorationProject: RestorationProjectMarker,
      OrganisationPledge: MapPledgeIcon,
    },
    availableEntityTypeNames: ['RestorationProject', 'ProtectionArea', 'Organisation', 'OrganisationPledge']
  };

  const UiConfig: UiConfig = {
    layouts: { pageLayouts, sliceLayouts, blockLayouts, defaultPageLayout: pageLayouts.PageLayoutDefault },
    theme: themeOverrides,
    icons: icons,
    menuBar: {
      backgroundColor: DARK_BLUE,
    },
    search: {
      isAvailable: true,
      extraDataHooks: {
        RestorationProject: useRestorationProjects as DataHook,
      },
    },
    header: {
      // showLinearNavigation: false,
      variant: 'overlay',
      activeColor: ORANGE,
      hoverColor: ORANGE
    },
    footer: {
      buttonColor: OCEAN_BLUE,
      backgroundColor: WARM_GREY,
    },
    entity: {
      labels: {
        Organisation: 'Organisation',
        OrganisationPledge: 'Pledge',
        RestorationProject: 'Project',
        ProtectionArea: 'Protection Area',
      },
      dataProviders: [
        CitationsProvider, FeedEntriesProvider, OrganisationsProvider, 
        OrganisationPledgesProvider, PagesProvider, PostsProvider, 
        RestorationProjectsProvider, VideosProvider,
      ],
      icons: {
        OrganisationType: OrganisationTypeIcon,
        FeedEntryType: ArticleTypeIcon,
        Author: PersonIcon,
        News: icons.NewsIcon,
        OrganisationPledge: PledgeIcon,
        RestorationProject: LocationIcon,
        ProtectionArea: RestorationProjectIcon,
      },
      tooltips: {
        Organisation: OrganisationTooltip,
        OrganisationPledge: OrganisationPledgeTooltip,
        RestorationProject: RestorationProjectTooltip,
      },
      values: {
        Person: PersonValue,
      }
    }
  }

  return (
    <LinkButtonReplaceable.Provider value={LinkButton}>
    <UiProvider value={UiConfig}>
    <AppStateProvider>
    <MapProvider value={mapConfig}>
    <ModalStateProvider>
    <FormProvider>
    <SiteSidebarProvider>
    <LinearNavigationProvider>
      <My.Container 
        includeTooltip
        hideOverflow={false}
        tooltipProps={
          {
            backgroundColor: '#2496BD',
            textColor: '#FFF',
            className: 'shp--react-tooltip',
          }
        }
        className={className}>
        {
          isReady && <My.Map />
        }
        {children}
        <My.Footer />
      </My.Container>
    </LinearNavigationProvider>
    </SiteSidebarProvider>
    </FormProvider>
    </ModalStateProvider>
    </MapProvider>
    </AppStateProvider>
    </UiProvider>
    
  </LinkButtonReplaceable.Provider>
  );
  
};

Layout.defaultProps = LayoutDefaultProps;

