import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Entity, EntityTypeName, Shapeable, Dictionary } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityGridFilterOptions, EntityGridFilterValuesChangeHandler, useSubTopics, useTrends,
usePosts, useVideos, TabsContainer, Tab, TabProps, useAvailableEntities, useLink, useLang, EntityCard, useKnowledgeHubState,
KnowledgeHubStateItem, useFeedEntries, useReady, ENTITY_CARD_MAX_WORDS, useCitations, useEntityTypeCards,
EntitySelectSizeVariant, useTopics, EntityTypeToggleItem, 
EntityGrid} from '@shapeable/ui';
import _, { reverse, filter, pickBy, size, sortBy, intersection, first, keys, map, get } from 'lodash';

import pluralize from 'pluralize';
import { sprintf } from 'sprintf-js';
//import { EntityGrid } from './entity-grid';
import { classNames } from '@shapeable/utils';
const cls = classNames('page-knowledge-hub-layout');

const ENTITY_SORT_BY: Partial<Record<EntityTypeName, string>> = {
  Post: 'published',
};

export type PageKnowledgeHubLayoutEntityItem<T = Entity> = {
  latestCount?: number;
  latestTitle?: string;
  latestButton?: React.FC<any>;
  label?: string;
  spacing?: number;
  items?: T[];
  cardComponent?: React.FC<any>;
  card?: React.FC<any>;
  cardMaxWords?: number;
  sortBy?: string;
  filterFieldNames?: string[];
  toggleTypes?: EntityTypeToggleItem[];
  filterFieldOptions?: EntityGridFilterOptions;
  reverse?: boolean;
};

export type PageKnowledgeHubLayoutEntityConfig = Partial<Record<EntityTypeName, PageKnowledgeHubLayoutEntityItem>>;

// -------- Types -------->

export type PageKnowledgeHubLayoutProps = Classable & HasChildren & { 
  entity: Entity;
  showAllTab?: boolean;
  allTabLabel?: string;
  slices?: React.ReactNode;
  tabProps?: Partial<TabProps>;
  cardMaxWords?: number;
  latestCount?: number;
  entityTypeNames?: EntityTypeName[];
  entityTypes?: PageKnowledgeHubLayoutEntityConfig;
  filterSize?: EntitySelectSizeVariant;
  filterSpacing?: number;
};

export const PageKnowledgeHubLayoutDefaultProps: Omit<PageKnowledgeHubLayoutProps, 'entity'> = {
  tabProps: {},
  showAllTab: true,
  allTabLabel: 'Latest',
  entityTypes: {},
};

// -------- Child Component Props -------->

type TabComponentProps = {
  tabCount: number;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});

const TabsStyles = breakpoints({
  base: css`
    min-height: 64px;
  `,
});


const TabStyles = breakpoints({
  base: css`
    // TODO - adapt to font system
    font-family: ${theme.FONT('serif')};
    font-size: 1em;
    ${({ tabCount }: TabComponentProps ) => tabCount !== 0 && css`
      flex-basis: ${100 / tabCount}%;
    `}

    &:last-child {
      border-right: none;
    }
  `,
  tablet: css`
    
  `
});

const BodyStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)} 0;
  `,
});

const LatestEntityStyles = breakpoints({
  base: css`
    
  `,
});

const TitleStyles = breakpoints({
  base: css`
    padding-left: ${theme.UNIT(3)};
    font-weight: 400;
  `,
});





// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    Body: styled.div.attrs(cls.attr('body'))`${BodyStyles}`,
    TabsContainer: styled(TabsContainer)`${TabsStyles}`,
      Tab: styled(Tab)<TabComponentProps>`${TabStyles}`,
    
    LatestEntity: styled.div.attrs(cls.attr('latest-entity'))`${LatestEntityStyles}`,
      Title: styled.h2.attrs(cls.attr('title'))`${TitleStyles}`,

    Grid: styled(EntityGrid)`${GridStyles}`,
};

export const PageKnowledgeHubLayout: Shapeable.FC<PageKnowledgeHubLayoutProps> = (props) => {
  const { className, slices, tabProps, filterSize, filterSpacing } = props;

  let activeEntityTypeName = '';
  if (typeof document !== 'undefined') {
    activeEntityTypeName = document.location.hash && document.location.hash.replace(/^#/, '');
  }

  const ENTITY_CARD_COMPONENTS = useEntityTypeCards();

  const [ state, setState, patchState ] = useKnowledgeHubState();
  const availableEntities = useAvailableEntities();

  const prepareEntity: (entityTypeName: EntityTypeName, items: Entity[]) => Entity[] = 
    (entityTypeName, items = []) => {
    const sortByField = get(props.entityTypes, `${entityTypeName}.sortBy`, ENTITY_SORT_BY[entityTypeName] || 'updated');
    const isReverse = get(props.entityTypes, `${entityTypeName}.reverse`, true);
    return isReverse ? reverse(sortBy(items, sortByField)) : sortBy(items, sortByField);
  };

  const providedEntities: Partial<Record<EntityTypeName, Entity[]>> = {
    FeedEntry: prepareEntity('FeedEntry', useFeedEntries()),
    Post: prepareEntity('Post', usePosts()),
    Video: prepareEntity('Video', useVideos()),
    Citation: prepareEntity('Citation', useCitations()),
    Trend: prepareEntity('Trend', useTrends()),
    Topic: prepareEntity('Topic', useTopics()),
    SubTopic: prepareEntity('SubTopic', useSubTopics()),
    Guidebook: prepareEntity('Guidebook', []),
    CaseStudy: prepareEntity('CaseStudy', []),
    Entity: prepareEntity('Entity', []),
  };

  const { navigate } = useLink();
  const t = useLang();

  const entityTypeKeys = keys(props.entityTypes) as EntityTypeName[];
  const entityTypeNamesSelected: EntityTypeName[] = (!!entityTypeKeys.length && entityTypeKeys) || (props.entityTypeNames && !!props.entityTypeNames.length && props.entityTypeNames) || availableEntities || [];

  const { isReady } = useReady();

  // no point showing the all tab if there is only one type of entity
  const showTabs = entityTypeNamesSelected.length > 1 && isReady;
  const showAllTab = showTabs && props.showAllTab;
  
  const entityTypes: PageKnowledgeHubLayoutEntityConfig = intersection(entityTypeNamesSelected, availableEntities).reduce((acc, entityTypeName) => {

    const entityTypeProps = (props.entityTypes[entityTypeName] || {});
    const pluralLabel = entityTypeProps.label || pluralize.plural(entityTypeName);

    return {
      ...acc,
      [entityTypeName]: {
        latestCount: 3,
        latestTitle: sprintf(t('Latest %s'), pluralLabel),
        items: providedEntities[entityTypeName] || [],
        label: sprintf(t('All %s'), pluralLabel),
        cardMaxWords: ENTITY_CARD_MAX_WORDS[entityTypeName] === undefined ? 40 : ENTITY_CARD_MAX_WORDS[entityTypeName],
        ...(entityTypeProps),
      }
    };
  }, {});

  const nonEmptyEntityTypes = (pickBy(entityTypes, entityType => !!entityType.items.length));
  const nonEmptyEntityCount = size(nonEmptyEntityTypes);
  const tabCount = nonEmptyEntityCount + (showAllTab ? 1 : 0);

  if (nonEmptyEntityCount <= 1 || (!showAllTab && activeEntityTypeName === '')) {
    activeEntityTypeName = first(keys(nonEmptyEntityTypes)) || ''
  }


  const onFilterValuesChange: EntityGridFilterValuesChangeHandler = (values) => {
  
    // console.log(values, activeEntityTypeName);

    if (activeEntityTypeName) {
      const newState: Dictionary<KnowledgeHubStateItem> = {
        ...state,
        [activeEntityTypeName]: {
          ...(state[activeEntityTypeName] || {}),
          filters: values,
        }
      };

      // console.log("NEW STATE", newState);

      setState(newState);
    }
  };
  

  // @ts-ignore
  const activeEntityType: PageKnowledgeHubLayoutEntityItem = entityTypes[activeEntityTypeName];
  const activeEntityFilterValues = state && state[activeEntityTypeName] && state[activeEntityTypeName].filters || {};

  return (
    <My.Container className={cls.name(className)}>
      {slices}
      { 
        nonEmptyEntityCount > 1 && 
        <My.TabsContainer>
        {
          showTabs && <>
          {
            showAllTab && 
            <My.Tab {...tabProps} tabCount={tabCount} isActive={activeEntityTypeName === ''} onClick={() => { document.location.hash = '' }}>{t('Latest')}</My.Tab>
          }
          {
            map(entityTypes, (config, entityTypeName) => (
              <My.Tab {...tabProps} tabCount={tabCount} isActive={activeEntityTypeName === entityTypeName} key={entityTypeName} onClick={() => { document.location.hash = entityTypeName; }}>{t(config.label)}</My.Tab>
            ))
          }            
          </>
        }
        </My.TabsContainer>
      }
      <My.Body>
      {
        (showAllTab && activeEntityTypeName === '') && 
        map(entityTypes, (config, entityTypeName) => {
          const items = ((config.items && config.latestCount) ? config.items.slice(0, config.latestCount) : config.items) || [];

          if (items.length) {
             return (
              <My.LatestEntity key={`latest-${entityTypeName}`}>
                <My.Title>{config.latestTitle}</My.Title>
                <My.Grid 
                  desktopAutoColumns={4}
                  desktopLargeAutoColumns={4}
                  anyCardProps={{ variant: 'chromeless' }}
                  spacing={config.spacing}
                  items={items}
                  card={config.cardComponent}
                  cardMaxWords={config.cardMaxWords}
                />
              </My.LatestEntity>
            );
          }
          
          return null;
        })
      }
      {
        activeEntityType &&
        <My.Grid
          filterSize={filterSize}
          filterSpacing={filterSpacing}
          spacing={activeEntityType.spacing}
          filterValues={activeEntityFilterValues}
          desktopAutoColumns={4}
          desktopLargeAutoColumns={4}
          anyCardProps={{ variant: 'chromeless' }}
          onFilterValuesChange={onFilterValuesChange}
          filterFieldNames={activeEntityType.filterFieldNames}
          toggleTypes={activeEntityType.toggleTypes}
          cardMaxWords={activeEntityType.cardMaxWords}
          items={activeEntityType.items}
          card={activeEntityType.cardComponent}
        />
      }
      </My.Body>
    </My.Container>
  )
};

PageKnowledgeHubLayout.defaultProps = PageKnowledgeHubLayoutDefaultProps;
PageKnowledgeHubLayout.cls = cls;